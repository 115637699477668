import en from "./translations/en.json";
import enUS from "./translations/en-us.json";
import enUK from "./translations/en-uk.json";
import enIN from "./translations/en-in.json";
import enAE from "./translations/en-ae.json";
import enSG from "./translations/en-sg.json";
import cs from "./translations/cs-cz.json";
import de from "./translations/de-de.json";
import dk from "./translations/dk-dk.json";
import ee from "./translations/ee-ee.json";
import es from "./translations/es-es.json";
import esLA from "./translations/es-la.json";
import fi from "./translations/fi-fi.json";
import fr from "./translations/fr-fr.json";
import hu from "./translations/hu-hu.json";
import id from "./translations/id-id.json";
import it from "./translations/it-it.json";
import ja from "./translations/ja-jp.json";
import ko from "./translations/ko-kr.json";
import nl from "./translations/nl-nl.json";
import pl from "./translations/pl-pl.json";
import ptbr from "./translations/pt-br.json";
import ptpt from "./translations/pt-pt.json";
import ru from "./translations/ru-ru.json";
import sv from "./translations/sv-se.json";
import th from "./translations/th-th.json";
import zhhans from "./translations/zh-hans.json";
import zhhant from "./translations/zh-hant.json";
import zhtw from "./translations/zh-tw.json";

export const i18nConfig = {
  defaultLocale: "en",
  messages: {
    cs: cs,
    "cs-cz": cs,
    ch: cs,
    de: de,
    "de-de": de,
    dk: dk,
    "dk-dk": dk,
    "da-dk": dk,
    da: dk,
    ee: ee,
    et: ee,
    "ee-ee": ee,
    "et-ee": ee,
    en: en,
    "en-ae": enAE,
    ae: enAE,
    "en-me": enAE,
    me: enAE,
    "en-gb": enUK,
    "en-uk": enUK,
    uk: enUK,
    gb: enUK,
    "en-in": enIN,
    in: enIN,
    "en-sg": enSG,
    "en-sea": enSG,
    sg: enSG,
    sea: enSG,
    "en-us": enUS,
    us: enUS,
    es: es,
    "es-es": es,
    la: esLA,
    "es-la": esLA,
    fi: fi,
    "fi-fi": fi,
    fr: fr,
    "fr-fr": fr,
    hu: hu,
    "hu-hu": hu,
    id: id,
    "id-id": id,
    it: it,
    "it-it": it,
    ja: ja,
    jp: ja,
    "jp-jp": ja,
    "ja-jp": ja,
    ko: ko,
    "ko-kr": ko,
    kr: ko,
    nl: nl,
    "nl-nl": nl,
    pl: pl,
    "pl-pl": pl,
    br: ptbr,
    "pt-br": ptbr,
    pt: ptpt,
    "pt-pt": ptpt,
    ru: ru,
    "ru-ru": ru,
    sv: sv,
    "sv-se": sv,
    se: sv,
    th: th,
    "th-th": th,
    "zh-cn": zhhans,
    cn: zhhans,
    zh: zhhans,
    "zh-hans": zhhans,
    "zh-hant": zhhant,
    hant: zhhant,
    "zh-tw": zhtw,
    tw: zhtw,
  },
};
