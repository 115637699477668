import classNames from "classnames";
import { observer } from "mobx-react";
import React, { Fragment, useContext, useState } from "react";
import { FormattedMessage } from "react-intl";
import _ from "underscore";

import { ExpandLessBold } from "../../assets/icons/ExpandLessBold";
import { ExpandMoreBold } from "../../assets/icons/ExpandMoreBold";
import { i18nConfig } from "../../i18nConfig";
import { CookiesSettingsDialogContext, CookiesSettingsDialogStore, ICookieDescription } from "./cookiesSettingsDialogStore";

/**
 * Renders the toggle component
 * @param onChange the function that will be called when toggle is changed
 * @param checked boolean that marks if the toggle is 'on'
 * @param disabled boolean that marks if the toggle is disabled
 */
const Toggle = observer(({ group }: { group: string }) => {
  const store: CookiesSettingsDialogStore = useContext(CookiesSettingsDialogContext);
  const disabled = group === "necessaryCookies";
  const groupKey = `enable${group.charAt(0).toUpperCase() + group.slice(1)}`;
  const checked = disabled || store.getCookiePreferences()[groupKey];

  const status = checked ? (
    <FormattedMessage id="toggle.on" defaultMessage={i18nConfig.messages[i18nConfig.defaultLocale]["toggle.on"]} />
  ) : (
    <FormattedMessage id="toggle.off" defaultMessage={i18nConfig.messages[i18nConfig.defaultLocale]["toggle.off"]} />
  );

  function handleChange(event) {
    store.storeCookiePreferences({ [groupKey]: event.target.checked });
  }

  return (
    <div className="cookie-preferences-switch">
      <label className="cookies-toggle">
        <input
          type="checkbox"
          onChange={handleChange}
          checked={checked}
          disabled={disabled}
          data-testid={`cookieToggle-${group}`}
        />
        <span className="cookies-toggle-slider"></span>
      </label>
      <span
        className={classNames("cookies-toggle-text", { disabled: disabled })}
        data-testid={`cookieToggleStatus-${group}`}
      >
        {status}
      </span>
    </div >
  );
});

const CookieTableRow = observer(({ cookie }: { cookie: ICookieDescription }) => {
  return (
    <tr className="cookie-list-item" data-testid={"cookieListItem-" + cookie["Cookie name"]}>
      <td>{cookie["Cookie name"]}</td>
      <td>{cookie["Host"]}</td>
      <td>{cookie["Expiry"]}</td>
      <td>{cookie["Purpose"]}</td>
    </tr>
  );
});

const CookieTableGroup = observer(({ provider, cookies }: { provider: string, cookies: ICookieDescription[] }) => {
  const [open, setOpen] = useState(false);

  return (
    <Fragment>
      <div className="cookie-list-provider" data-testid={`cookieProvider-${provider}`} onClick={() => setOpen(!open)}>
        <span>{provider}</span>
        <span className="cookie-list-provider-arrow">{open ? <ExpandLessBold /> : <ExpandMoreBold />}</span>
      </div>
      {open &&
        (<table data-testid={`cookieDescriptionsTable-${provider}`}>
          <thead>
            <tr>
              <th>
                <FormattedMessage
                  id={"preferences.cookieTable.cookieName"}
                  defaultMessage={i18nConfig.messages[i18nConfig.defaultLocale]["preferences.cookieTable.cookieName"]}
                />
              </th>
              <th>
                <FormattedMessage
                  id={"preferences.cookieTable.host"}
                  defaultMessage={i18nConfig.messages[i18nConfig.defaultLocale]["preferences.cookieTable.host"]}
                />
              </th>
              <th>
                <FormattedMessage
                  id={"preferences.cookieTable.expiry"}
                  defaultMessage={i18nConfig.messages[i18nConfig.defaultLocale]["preferences.cookieTable.expiry"]}
                />
              </th>
              <th>
                <FormattedMessage
                  id={"preferences.cookieTable.purpose"}
                  defaultMessage={i18nConfig.messages[i18nConfig.defaultLocale]["preferences.cookieTable.purpose"]}
                />
              </th>
            </tr>
          </thead>
          <tbody>
            {_.map(cookies, (cookie: ICookieDescription, i: number) => (
              <CookieTableRow cookie={cookie} key={i} />
            ))}
          </tbody>
        </table>)
      }
    </Fragment >
  );
});

const CookieTable = observer(({ group }: { group: string }) => {
  const store: CookiesSettingsDialogStore = useContext(CookiesSettingsDialogContext);

  function getCookieList() {
    if (group === "necessaryCookies") {
      return store.getStrictrlyNecessaryCookieList()
    } else if (group === "performanceCookies") {
      return store.getPerformanceCookieList()
    } else if (group === "targetingCookies") {
      return store.getTargetingCookieList()
    } else {
      return [];
    }
  }

  const groupedCookieList = _.chain(getCookieList())
    .sortBy((cookie: ICookieDescription) => cookie["Provider"])
    .groupBy((cookie: ICookieDescription) => cookie["Provider"])
    .value() as Record<string, ICookieDescription[]>;

  return (

    <div className="cookies-table" data-testid={group + "List"}>
      {_.map(groupedCookieList, (group: ICookieDescription[], key: string) => (
        <CookieTableGroup provider={key} cookies={group} key={key} />
      ))}
    </div>
  );
});

const ThirdPartyCookiesInfo = () => (
  <Fragment>
    <h5>
      <FormattedMessage id={`preferences.targetingCookies.third_party.title`} defaultMessage={i18nConfig.messages[i18nConfig.defaultLocale][`preferences.targetingCookies.third_party.title`]} />
    </h5>
    <p>
      <FormattedMessage
        id={`preferences.targetingCookies.third_party.description`}
        defaultMessage={i18nConfig.messages[i18nConfig.defaultLocale][`preferences.targetingCookies.third_party.description`]}
        values={{
          cookieNoticeLink: (
            <a href="https://www.tekla.com/tekla-cookie-notice" rel="noopener noreferrer" target="_blank">
              <FormattedMessage
                id="cookieNoticeLinkText"
                defaultMessage={i18nConfig.messages[i18nConfig.defaultLocale]["cookieNoticeLinkText"]}
              />
            </a>
          ),
        }} />
    </p>
  </Fragment>
)

/**
 * Handles the visibility of cookie groups
 * @param group Name of the cookie group
 */
const CookieGroup = ({ group }: { group: string }) => {
  const [visible, setVisible] = useState(false);
  const groupClassName = "cookies-group-name" + (visible ? " active-group" : "");

  const showThirdPartyCookiesInfo = group === "targetingCookies";

  function handleShowDescription() {
    if (visible) {
      setVisible(false);
    } else {
      setVisible(true);
    }
  }

  return (
    <div className="cookie-preference-group" data-testid={group}>
      <section className="cookie-preferences-information">
        <div className="cookie-group">
          <label className={groupClassName} onClick={handleShowDescription} data-testid={`cookieGroupName-${group}`}>
            {visible ? <ExpandLessBold /> : <ExpandMoreBold />}
            <h4>
              <FormattedMessage id={`preferences.${group}.name`} defaultMessage={i18nConfig.messages[i18nConfig.defaultLocale][`preferences.${group}.name`]} />
            </h4>
          </label>
          {visible && (
            <div className="cookies-group-description" data-testid={`cookieGroupDescription-${group}`}>
              <p>
                <FormattedMessage
                  id={`preferences.${group}.description`}
                  defaultMessage={i18nConfig.messages[i18nConfig.defaultLocale][`preferences.${group}.description`]}
                />
              </p>
              {showThirdPartyCookiesInfo && <ThirdPartyCookiesInfo />}
            </div>
          )}
        </div>
        <Toggle group={group} />
      </section >
      {visible && <CookieTable group={group} />}
    </div>
  );
};

/**
 * Component that renders the 'Preference groups' part of the Edit preferences dialog.
 */
export const PreferenceGroups = observer(() => {
  return (
    <section className="dialog-body">
      <CookieGroup group="necessaryCookies" />
      <hr className="cookies-group-separator" />
      <CookieGroup group="performanceCookies" />
      <hr className="cookies-group-separator" />
      <CookieGroup group="targetingCookies" />
    </section >
  );
});
