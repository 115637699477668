import React from "react";
import { FooterTop } from "./footer-top/FooterTop";
import { FooterBottom } from "./footer-bottom/FooterBottom";

/**
 * Component that renders the footer
 */
export const App = ({ ...props }: { canUseSiteBeforeAction?: boolean }) => {
  return (
    <div className="footer-base" data-testid="teklaFooterBase">
      <FooterTop />
      <FooterBottom canUseSiteBeforeAction={props.canUseSiteBeforeAction} />
    </div>
  );
};
