import React, { Fragment, useState, useEffect, useContext, createRef, RefObject } from "react";
import ReactModal from "react-modal";
import classNames from "classnames";
import { observer } from "mobx-react";
import { FormattedMessage } from "react-intl";

import { CookiesSettingsDialogContext, CookiesSettingsDialogStore } from "./cookiesSettingsDialogStore";
import { CookieBanner } from "./CookieBanner";
import { EditPreferencesView } from "./EditPreferencesView";
import { i18nConfig } from "../../i18nConfig";
import { ScrollDownIcon } from "../../assets/icons/ScrollDownIcon";

/**
 * Component that renders the two different cookie dialog content views.
 */
const CookiePreferencesDialogContent = observer(() => {
  const dialog: CookiesSettingsDialogStore = useContext(CookiesSettingsDialogContext);
  const [showScrollDown, setShowScrollDown] = useState(false);
  const dialogContent: RefObject<HTMLElement> = createRef();

  useEffect(() => {
    setShowScrollDown(checkIfArrowShouldBeVisible());
  }, [dialog.isPreferenceViewVisible()]);

  function checkIfArrowShouldBeVisible(): boolean {
    const contentRef = dialogContent.current;
    let shouldBeVisible = false;

    if (contentRef) {
      const articleContentOverflows = contentRef.scrollHeight > contentRef.clientHeight;
      const reachedBottom = contentRef.scrollHeight - Math.round(contentRef.scrollTop) <= contentRef.clientHeight + 1;
      dialog.setContentOverflows(articleContentOverflows);
      shouldBeVisible = dialog.isPreferenceViewVisible() && articleContentOverflows && !reachedBottom;
    }

    return shouldBeVisible;
  }

  function handleScroll() {
    setShowScrollDown(checkIfArrowShouldBeVisible());
  }

  return (
    <Fragment>
      {showScrollDown && <ScrollDownIcon />}
      <article
        className={classNames("cookies-settings-dialog-content", {
          "preference-view": dialog.isPreferenceViewVisible(),
        })}
        data-testid="dialogContent"
        onScroll={handleScroll}
        ref={dialogContent}
      >
        {dialog.isPreferenceViewVisible() ? <EditPreferencesView /> : <CookieBanner />}
      </article>
    </Fragment>
  );
});

const CookiePreferencesDialog = observer(({ ...props }: { canUseSiteBeforeAction?: boolean }) => {
  const dialog: CookiesSettingsDialogStore = useContext(CookiesSettingsDialogContext);

  return (
    <ReactModal
      isOpen={dialog.isDialogOpen()}
      className={classNames("cookies-settings-dialog", {
        banner: !dialog.isPreferenceViewVisible(),
        "preference-view": dialog.isPreferenceViewVisible(),
        "with-long-content": dialog.hasOverflowContent(),
      })}
      overlayClassName={classNames("cookies-settings-dialog-overlay", {
        "show-background": props.canUseSiteBeforeAction,
      })}
      ariaHideApp={false}
    >
      <CookiePreferencesDialogContent />
    </ReactModal>
  );
});

/**
 * Component that renders the 'Cookie preferences' link and handles the cookie preferences dialog.
 */
export const CookiePreferences = ({ ...props }: { canUseSiteBeforeAction?: boolean }) => {
  const dialog: CookiesSettingsDialogStore = new CookiesSettingsDialogStore();

  useEffect(() => {
    if (props.canUseSiteBeforeAction) {
      dialog.enableCookiesByDefault();
    }
  }, []);

  function openDialog() {
    dialog.openDialog();
  }

  return (
    <CookiesSettingsDialogContext.Provider value={dialog}>
      <button onClick={openDialog} className="cookie-preferences-link link" data-testid="cookieLink">
        <FormattedMessage
          id="cookiePreferences"
          defaultMessage={i18nConfig.messages[i18nConfig.defaultLocale]["cookiePreferences"]}
        />
      </button>

      <CookiePreferencesDialog canUseSiteBeforeAction={props.canUseSiteBeforeAction} />
    </CookiesSettingsDialogContext.Provider>
  );
};
