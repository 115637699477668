import React from "react";

export const LinkedInIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
    <g clipPath="url(#clip0_375_3362)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 0C24.8366 0 32 7.16345 32 16C32 24.8366 24.8366 32 16 32C7.16345 32 0 24.8366 0 16C0 7.16345 7.16345 0 16 0ZM19.6749 13.2963C18.0508 13.2963 17.0331 14.264 16.8449 14.942V13.3914H13.6602C13.6718 13.624 13.6768 14.5143 13.6781 15.6721L13.678 17.1044C13.6756 19.6154 13.6633 22.5598 13.6606 23.1619L13.6602 23.2727H16.8449V17.926C16.8449 17.6279 16.8321 17.3309 16.9142 17.1185C17.1331 16.5234 17.6035 15.9078 18.4493 15.9078C19.5551 15.9078 20.058 16.8205 20.058 18.1588V23.2727H23.2727V17.7779C23.2727 14.7185 21.6897 13.2963 19.6749 13.2963ZM11.9292 13.3914H9.09416V23.2718H11.9292V13.3914ZM10.4591 8.72727C9.4123 8.72727 8.72727 9.46768 8.72727 10.4437C8.72727 11.4002 9.39263 12.1555 10.4189 12.1555H10.4394C11.505 12.1555 12.1686 11.3973 12.1686 10.4409C12.1482 9.46581 11.505 8.72727 10.4591 8.72727Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_375_3362">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
