import React from "react";

// https://modus-icons.trimble.com/modus-solid/chevron-double-down/
export const ScrollDownIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="#b7b9c3"
    className="scroll-down-icon"
    viewBox="0 0 24 24"
    data-testid="scrollDownIcon"
  >
    <path d="M11.29 11.7c.39.39 1.02.39 1.41 0l4.59-4.59c.39-.38.39-1.01 0-1.4s-1.02-.39-1.41 0L12 9.58 8.11 5.7A.996.996 0 1 0 6.7 7.11l4.59 4.59Zm6 .6a.996.996 0 0 0-1.41 0L12 16.17l-3.88-3.88a.996.996 0 1 0-1.41 1.41l4.59 4.59c.39.39 1.02.39 1.41 0l4.59-4.59a.993.993 0 0 0-.01-1.4Z" />
  </svg>
);
