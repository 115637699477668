import React from "react";
import { FormattedMessage } from "react-intl";
import { DateTime } from "luxon";

import { TrimbleLogo } from "../../assets/logos/TrimbleLogo";
import { PrivacyOptionsIcon } from "../../assets/icons/PrivacyOptionsIcon";
import { CookiePreferences } from "../cookie-dialog/CookiePreferences";
import { i18nConfig } from "../../i18nConfig";
import { FooterLink } from "./FooterLink";

/**
 * Component that renders the footer
 */
export const FooterBottom = ({ canUseSiteBeforeAction }: { canUseSiteBeforeAction?: boolean }) => {
  const footerBottomLinks = [
    { link: "termsAndConditions" },
    { link: "trustCenter" },
    { link: "privacy" },
    {
      link: "yourPrivacyChoices",
      icon: <PrivacyOptionsIcon/>,
    },
    { link: "noticeAtCollection" },
    { link: "trademarks" },
  ];

  return (
    <div className="footer-bottom" data-testid="footerBottom">
      <div className="footer-content">
        <div className="trimble-logo">
          <TrimbleLogo />
          <p className="mini" data-testid="copyrightInfo">
            <FormattedMessage
              id="footer.copyrightInfo"
              defaultMessage={i18nConfig.messages[i18nConfig.defaultLocale]["footer.copyrightInfo"]}
              values={{ year: DateTime.now().year }}
            />
          </p>
        </div>
        <div className="footer-description" data-testid="footerDescription">
          <p>
            <FormattedMessage
              id="footer.description.part1"
              defaultMessage={i18nConfig.messages[i18nConfig.defaultLocale]["footer.description.part1"]}
            />
          </p>
          <p>
            <FormattedMessage
              id="footer.description.part2"
              defaultMessage={i18nConfig.messages[i18nConfig.defaultLocale]["footer.description.part2"]}
            />
          </p>
          <span>
            <FooterLink link="trimble" />
          </span>
        </div>
        <div className="links" data-testid="legalLinks">
          {footerBottomLinks.map((obj: { link: string; icon?: React.JSX.Element }, i: number) => (
            <p key={i}>
              <FooterLink link={obj.link} icon={obj.icon} />
            </p>
          ))}
          <p>
            <CookiePreferences canUseSiteBeforeAction={canUseSiteBeforeAction} />
          </p>
        </div>
      </div>
    </div>
  );
};
