import React, { Fragment } from "react";
import { useContext } from "react";
import { observer } from "mobx-react";
import { FormattedMessage } from "react-intl";

import { CookiesSettingsDialogContext, CookiesSettingsDialogStore } from "./cookiesSettingsDialogStore";
import { i18nConfig } from "../../i18nConfig";

/**
 * Component that renders the banner view of the 'Cookie preferences' dialog.
 */
export const CookieBanner = observer(() => {
  const dialog: CookiesSettingsDialogStore = useContext(CookiesSettingsDialogContext);

  function handleOpenPreferences() {
    dialog.openPreferenceView();
  }

  function handleAcceptAllCookies() {
    dialog.acceptAllCookies(true);
  }

  return (
    <Fragment>
      <div className="cookies-dialog-header" data-testid="cookieDialogHeader">
        <h3>
          <FormattedMessage
            id="cookiePreferences"
            defaultMessage={i18nConfig.messages[i18nConfig.defaultLocale]["cookiePreferences"]}
          />
        </h3>
      </div>
      <div className="cookies-text-content" data-testid="cookieBanner">
        <p className="cookies-general-information">
          <FormattedMessage
            id="landingText.part1"
            defaultMessage={i18nConfig.messages[i18nConfig.defaultLocale]["landingText.part1"]}
          />{" "}
          <FormattedMessage
            id="landingText.part2"
            defaultMessage={i18nConfig.messages[i18nConfig.defaultLocale]["landingText.part2"]}
          />{" "}
        </p>
        <p>
          <FormattedMessage
            id="cookieNotice"
            defaultMessage={i18nConfig.messages[i18nConfig.defaultLocale]["cookieNotice"]}
            values={{
              cookieNoticeLink: (
                <a href="https://www.tekla.com/tekla-cookie-notice" rel="noopener noreferrer" target="_blank">
                  <FormattedMessage
                    id="cookieNoticeLinkText"
                    defaultMessage={i18nConfig.messages[i18nConfig.defaultLocale]["cookieNoticeLinkText"]}
                  />
                </a>
              ),
            }}
          />
        </p>
      </div>
      <footer className="cookies-actions">
        <div className="secondary-action">
          <button className="cookies-button secondary" onClick={handleOpenPreferences}>
            <FormattedMessage
              id="actions.editPreferences"
              defaultMessage={i18nConfig.messages[i18nConfig.defaultLocale]["actions.editPreferences"]}
            />
          </button>
        </div>
        <div className="primary-action">
          <button className="cookies-button primary" onClick={handleAcceptAllCookies}>
            <FormattedMessage
              id="actions.acceptAll"
              defaultMessage={i18nConfig.messages[i18nConfig.defaultLocale]["actions.acceptAll"]}
            />
          </button>
        </div>
      </footer>
    </Fragment>
  );
});
