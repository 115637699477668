import { createContext } from "react";
import { action, makeObservable, observable } from "mobx";
import Cookies from "js-cookie";
import { fromBase64, toBase64 } from "js-base64";
import _ from "underscore";

import cookieList from "../../assets/files/TeklaOnlineCookieList.json";

import { ICookiesSettings } from "./cookiesSettings";

export interface ICookieDescription {
    Provider: string;
    Category: string;
    "Cookie name": string;
    Host: string;
    Expiry: string;
    Purpose: string;
}

/**
 * Store responsible to provide methods to read and
 * write cookies settings.
 */
export class CookiesSettingsDialogStore {
  private readonly CookiesSettingsCookieName = "cookies.settings";

  /* Flag that marks if the dialog is open. */
  @observable private dialogOpen = false;

  /* Flag that marks if the 'Preference view' of the dialog is open. */
  @observable private showPreferenceView = false;

  /* Flag that marks if the dialog has overflow content. */
  @observable private contentOverflows = false;

  /* Flag that marks if cookies should be allowed by default. */
  @observable private defaultOptIn = false;

  /* Cookie preferences object. */
  @observable private cookiePreferences: ICookiesSettings = {
    enablePerformanceCookies: true,
    enableTargetingCookies: true,
    userActionTaken: false,
  };

  public constructor() {
    makeObservable(this);
  }

  /**
   * Opens the dialog.
   */
  @action
  public openDialog(): void {
    this.dialogOpen = true;
  }

  /**
   * Changes to the 'Set cookie preferences' view.
   */
  @action
  public openPreferenceView() {
    this.dialogOpen = true;
    this.showPreferenceView = true;
  }

  /**
   * Changes back to the banner view.
   */
  @action
  public returnToLanding() {
    this.showPreferenceView = false;
  }

  /**
   * Closes the dialog.
   */
  @action
  public closeDialog() {
    this.dialogOpen = false;
    this.showPreferenceView = false;
  }

  /**
   * Checks if the dialog is open.
   */
  public isDialogOpen(): boolean {
    return !this.settingsCookieExists() || !this.userActionTaken() || this.dialogOpen;
  }

  /**
   * Checks if the dialog is open.
   */
  public init() {
    const settingsFromCookie = this.getCookiesSettings();

    this.storeCookiePreferences({ 
      enableTargetingCookies: settingsFromCookie.enableTargetingCookies,
      enablePerformanceCookies: settingsFromCookie.enablePerformanceCookies
    });
  }

  /**
   * Checks if the 'Set cookie preferences' view is visible.
   */
  public isPreferenceViewVisible(): boolean {
    return this.showPreferenceView;
  }

  /**
   * Checks if the dialog has long content.
   */
  public hasOverflowContent(): boolean {
    return this.contentOverflows;
  }

  /**
   * Sets the value for contentOverflows.
   */
  @action
  public setContentOverflows(value: boolean) {
    this.contentOverflows = value;
  }

  /**
   * Returns a boolean that indicates whether cookies are accepted by default.
   */
  public areCookiesAcceptedByDefault(): boolean {
    return this.defaultOptIn;
  }

  /**
   * Enables all cookies.
   */
  @action
  public enableCookiesByDefault() {
    this.defaultOptIn = true;

    if (!this.userActionTaken() && !this.settingsCookieExists()) {
      this.acceptAllCookies(false);
    }
  }

  /**
   * Stores the cookie preferences.
   */
  @action
  public storeCookiePreferences(cookiePreferences: Record<string, boolean>): void {
    this.cookiePreferences = _.extend(this.cookiePreferences, cookiePreferences);
  }

  /**
   * Returns the cookie preferences object.
   */
   @action
   public getCookiePreferences(): ICookiesSettings {
     return this.cookiePreferences;
   }

  /**
   * Retrieves the list of strictly necessary cookies.
   * @returns An array of `ICookieDescription` objects representing the strictly necessary cookies.
   */
  public getStrictrlyNecessaryCookieList(): ICookieDescription[] {
    return cookieList.filter(cookie => cookie.Category === "Strictly necessary");
  }

  /**
   * Retrieves the list of performance cookies.
   * @returns An array of `ICookieDescription` objects representing performance cookies.
   */
  public getPerformanceCookieList(): ICookieDescription[] {
    return cookieList.filter(cookie => cookie.Category === "Performance");
  }

  /**
   * Retrieves the list of targeting cookies.
   * @returns An array of `ICookieDescription` objects representing targeting cookies.
   */
  public getTargetingCookieList(): ICookieDescription[] {
    return cookieList.filter(cookie => cookie.Category === "Targeting / Marketing");
  }

  /**
   * Gets the cookie settings.
   * @returns the cookie settings
   */
  public getCookiesSettings(): ICookiesSettings {
    let cookiesSettings: ICookiesSettings = {
      enablePerformanceCookies: false,
      enableTargetingCookies: false,
      userActionTaken: false,
    };
    const cookie = Cookies.get(this.CookiesSettingsCookieName);

    if (cookie !== null && cookie !== undefined) {
      const json = fromBase64(cookie);
      cookiesSettings = JSON.parse(json);
    }

    return cookiesSettings;
  }

  /**
   * Sets the cookie settings.
   */
  @action
  public saveCookiePreferences(): void {
    this.cookiePreferences.userActionTaken = true;
    const json = JSON.stringify(this.cookiePreferences);
    const cookie = toBase64(json);
    const cookieAttributes: Cookies.CookieAttributes = this.getCookieAttributes();

    Cookies.set(this.CookiesSettingsCookieName, cookie, cookieAttributes);
  }

  /**
   * Sets the cookie settings.
   * @param userActionTaken has user taken action to accept the cookies?
   */
  @action
  public acceptAllCookies(userActionTaken: boolean): void {
    const json = JSON.stringify({ enablePerformanceCookies: true, enableTargetingCookies: true, userActionTaken: userActionTaken });
    const cookie = toBase64(json);
    const cookieAttributes: Cookies.CookieAttributes = this.getCookieAttributes();
    Cookies.set(this.CookiesSettingsCookieName, cookie, cookieAttributes);

    this.closeDialog();
    window.location.reload();
  }

  /**
   * Sets attributes according to hostname (local env/other)
   */
  private getCookieAttributes(): Cookies.CookieAttributes {
    const exp = new Date();
    exp.setFullYear(exp.getFullYear() + 1);

    if (this.isLocalEnv(window.location.hostname)) {
      return {
        expires: exp,
        domain: window.location.hostname,
      };
    } else {
      return {
        expires: exp,
        domain: ".tekla.com",
        secure: true,
        sameSite: "none",
      };
    }
  }

  private isLocalEnv(hostname: string): boolean {
    return (
      hostname === "localhost" ||
      /local\.[\S\D]+\.tekla\.com/i.test(hostname) ||
      /[\S\D]+local\.tekla\.com/i.test(hostname) ||
      /tekla\.com\.ddev\.site/i.test(hostname) ||
      /[\S\D]+\.tekla\.test/i.test(hostname)
    );
  }

  /**
   * Checks whether the settings cookie exists.
   * @returns true if the cookie exists, false if it doesn't
   */
  private settingsCookieExists(): boolean {
    const cookie = Cookies.get(this.CookiesSettingsCookieName);
    return cookie !== undefined && cookie !== null;
  }

  /**
   * Checks if user has taken action with the cookie banner.
   * @returns boolean indicating wheter user action has been taken
   */
  private userActionTaken(): boolean {
    const currentSettings = this.getCookiesSettings();
    return currentSettings.userActionTaken;
  }
}

export const CookiesSettingsDialogContext = createContext<CookiesSettingsDialogStore>(new CookiesSettingsDialogStore());
