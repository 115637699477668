import React, { ReactElement } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { i18nConfig } from "../../i18nConfig";

/**
 * Renders a footer link
 * @param link link name
 */
export const FooterLink = ({ link, icon }: { link: string; icon?: ReactElement }) => {
  const intl = useIntl();

  return (
    <a
      href={intl.formatMessage({
        id: `footer.links.${link}.url`,
        defaultMessage: i18nConfig.messages[i18nConfig.defaultLocale][`footer.links.${link}.url`],
      })}
      target="_blank"
      rel="noreferrer"
      data-testid={`${link}-link`}
    >
      {
        <FormattedMessage
          id={`footer.links.${link}.title`}
          defaultMessage={i18nConfig.messages[i18nConfig.defaultLocale][`footer.links.${link}.title`]}
          values={{ icon: icon }}
        />
      }
    </a>
  );
};
