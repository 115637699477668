import React from "react";

export const ChevronRight = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="chevron-right">
      <path
        id="Vector"
        d="M9.57042 6.70978C9.48085 6.80229 9.40978 6.91218 9.36129 7.03315C9.3128 7.15413 9.28784 7.28381 9.28784 7.41478C9.28784 7.54575 9.3128 7.67543 9.36129 7.7964C9.40978 7.91738 9.48085 8.02726 9.57042 8.11978L13.3196 11.9998L9.57042 15.8798C9.48096 15.9724 9.41 16.0823 9.36159 16.2032C9.31317 16.3242 9.28825 16.4538 9.28825 16.5848C9.28825 16.7157 9.31317 16.8454 9.36159 16.9663C9.41 17.0873 9.48096 17.1972 9.57042 17.2898C9.65988 17.3824 9.76609 17.4558 9.88297 17.5059C9.99986 17.556 10.1251 17.5818 10.2516 17.5818C10.3782 17.5818 10.5034 17.556 10.6203 17.5059C10.7372 17.4558 10.8434 17.3824 10.9329 17.2898L15.3681 12.6998C15.4576 12.6073 15.5287 12.4974 15.5772 12.3764C15.6257 12.2554 15.6507 12.1257 15.6507 11.9948C15.6507 11.8638 15.6257 11.7341 15.5772 11.6132C15.5287 11.4922 15.4576 11.3823 15.3681 11.2898L10.9329 6.69978C10.5657 6.31978 9.94727 6.31978 9.57042 6.70978Z"
        fill="white"
      />
    </g>
  </svg>
);
