import React from "react";

export const TrimbleLogo = () => (
  <svg
    id="Art_Black"
    data-name="Art Black"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 444.5 100"
    data-testid="trimbleLogo"
  >
    <path d="M115.68,87.19v-62H92.81v-14h62.56v14h-23v62Z" fill="#fff" />
    <path
      d="M141.73,87.19V32.81h16.51v8.83h.11c3-4.62,8.2-10.26,18.46-10.26h.52V46.05c-.62-.1-3.49-.3-4.52-.3a18.28,18.28,0,0,0-14.57,7.48v34Z"
      fill="#fff"
    />
    <path d="M182.55,25V11.79h16.62V25Zm.1,62.17V32.51h16.41V87.19Z" fill="#fff" />
    <path
      d="M205.52,87.19V32.51H222v8.62c3.28-4.31,9.33-9.75,18-9.75,9.13,0,13.44,3.8,15,9.75,3.28-4.21,9.44-9.75,18.06-9.75,11.08,0,16,7,16,16.72V87.19H272.51V52.52c0-4.93-1.13-7.49-5.95-7.49-4.1,0-7.38,2.25-11,5.43V87.19H239.07V52.52c0-4.93-1.13-7.49-5.95-7.49-4.11,0-7.39,2.25-11.08,5.43V87.19Z"
      fill="#fff"
    />
    <path
      d="M311.79,80.73v6.46H295.27V11.27h16.52V40.92c3.59-5,8.72-9.54,16.83-9.54,12.41,0,21,9.54,21,28.42S340.93,88,328.41,88C321,88,315.9,85.35,311.79,80.73ZM333.13,59.9C333.13,50.77,330,45,322.56,45c-4.2,0-8,2.56-10.77,5.33V70.57c3.59,3.39,6.57,5,11,5C329.64,75.6,333.13,69.85,333.13,59.9Z"
      fill="#fff"
    />
    <path d="M354.76,87.19V11.27h16.41V87.19Z" fill="#fff" />
    <path
      d="M376.29,59.9c0-21.23,16.21-28.52,27.5-28.52S428.92,37,428.92,61.85v2.46H392.71c.82,9,5.85,12.52,12.72,12.52a25.1,25.1,0,0,0,16.21-6.57L428,80.11c-6.36,5.44-14.26,8.21-23.8,8.21C389.94,88.32,376.29,81,376.29,59.9Zm37.86-5.33c-.82-7.7-4.31-11.39-10.47-11.39-5.12,0-9.54,3.39-10.77,11.39Z"
      fill="#fff"
    />
    <path
      d="M432,81a6.19,6.19,0,0,1,6.26-6.25A6.26,6.26,0,1,1,432,81Zm11.38,0a5.14,5.14,0,1,0-5.12,5.24A5.11,5.11,0,0,0,443.42,81Zm-7.55-3.56h2.56c1.62,0,2.57.71,2.57,2.11a1.84,1.84,0,0,1-1.35,1.94l1.48,2.81H439.7l-1.37-2.62h-1.08v2.62h-1.38Zm2.48,3.19c.84,0,1.3-.27,1.3-1s-.46-1-1.32-1h-1.08v2Z"
      fill="#fff"
    />
    <path d="M6.19,76.41V100L26.71,88.16A39.1,39.1,0,0,1,6.19,76.41Z" fill="#fff" />
    <path d="M26.56,11.74,6.19,0V23.45A39.33,39.33,0,0,1,26.56,11.74Z" fill="#fff" />
    <path d="M92.8,50,72.29,38.16a39.27,39.27,0,0,1-.06,23.72Z" fill="#fff" />
    <path
      d="M19.7,17.92c2.94-.65,9.05,0,12.28.89a18.68,18.68,0,0,1,10.37-3.45A41.12,41.12,0,0,0,19.7,17.92Z"
      fill="#fff"
    />
    <path
      d="M58.79,60.84a53.07,53.07,0,0,0-3.21-13.13c-7,3.18-15.72,1.13-21.53-3.9A95.93,95.93,0,0,0,22.62,58.32C32.47,66.35,48.67,68.46,58.79,60.84Z"
      fill="#fff"
    />
    <path
      d="M17.23,59.09C8.55,50.19,5.52,37.22,8.3,27.25a35,35,0,0,0,2,47.47A63.4,63.4,0,0,1,17.23,59.09Z"
      fill="#fff"
    />
    <path
      d="M54,43.94a47.18,47.18,0,0,0-7-10.78A80.73,80.73,0,0,0,37,40.93C42.26,45.3,49.14,46.16,54,43.94Z"
      fill="#fff"
    />
    <path
      d="M47.47,27.72a36.33,36.33,0,0,1,7.62-4.23c-5.56-5.62-14.5-5.47-18.39-3A37.17,37.17,0,0,1,47.47,27.72Z"
      fill="#fff"
    />
    <path
      d="M20.4,62c-4.32,7.47-5.9,13-6.48,15.87A35,35,0,0,0,57.1,77a30,30,0,0,0,2-11.33C47.14,72.79,30.66,70.07,20.4,62Z"
      fill="#fff"
    />
    <path
      d="M57.4,41.45c3.83-4,3.06-10.84.15-14.78a29.6,29.6,0,0,0-7.13,4.18A52.73,52.73,0,0,1,57.4,41.45Z"
      fill="#fff"
    />
    <path
      d="M64,32.85a14.1,14.1,0,0,1-4.84,12.59A57.37,57.37,0,0,1,62.49,57.2C67.74,50.38,67.58,40.64,64,32.85Z"
      fill="#fff"
    />
    <path
      d="M34.16,37.9A83.13,83.13,0,0,1,44,30.11a33.17,33.17,0,0,0-10.82-6.59C29.92,27.89,30.92,33.53,34.16,37.9Z"
      fill="#fff"
    />
    <path
      d="M63.12,62.69a49.42,49.42,0,0,1-.43,8.88A35.37,35.37,0,0,0,70,47.07,26.82,26.82,0,0,1,63.12,62.69Z"
      fill="#fff"
    />
    <path
      d="M29.1,22.28a28.42,28.42,0,0,0-13.9.09c-6.89,9.77-3.69,24.08,4.33,33A100.8,100.8,0,0,1,31.18,40.79C27.1,35.72,25.48,27.89,29.1,22.28Z"
      fill="#fff"
    />
  </svg>
);
