import React from "react";

// https://modus-icons.trimble.com/modus-solid/expand-more-bold/
export const ExpandMoreBold = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="currentColor"
    className="arrow-closed"
    viewBox="0 0 20 20"
  >
    <path d="M15.88 9.29 12 13.17 8.12 9.29a.996.996 0 1 0-1.41 1.41l4.59 4.59c.39.39 1.02.39 1.41 0l4.59-4.59a.996.996 0 0 0 0-1.41c-.39-.38-1.03-.39-1.42 0Z" />
  </svg>
);
