import React from "react";

// https://modus-icons.trimble.com/modus-solid/expand-less-bold/
export const ExpandLessBold = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="currentColor"
    className="arrow-open"
    viewBox="0 0 20 20"
  >
    <path d="M11.29 8.71 6.7 13.3a.996.996 0 1 0 1.41 1.41L12 10.83l3.88 3.88a.996.996 0 1 0 1.41-1.41L12.7 8.71a.996.996 0 0 0-1.41 0Z" />
  </svg>
);
